.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gridList {
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
}

.gridListItemWrap {
  background:#f8f8f8;
  width:calc(25% - 20px);
  margin:10px;
  border-radius:5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  -moz-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display:flex;
  flex-direction:column;
}
.gridListItemPicture {
width:100%;
padding-top:65%;
background:lightblue;
border-top-left-radius:5px;
border-top-right-radius:5px;
}
.gridListItemBottom {
  display:flex;
  flex-direction:column;
  flex:;
}
.gridListTextWrap {
  padding:10px;
}
.gridListTextName {
  font-size:18px;
  line-height:1.2;
}
.gridListTextTagline {
  font-size:12px;
  line-height:1.2;
}
.gridListBottom {
  display:flex;
  flex-direction:column;
  flex:1;
}
.gridListTagCloud {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  margin-left:10px;
  margin-right:10px;
  flex:1;
  align-items:flex-end;
}
.gridListTag {
  font-size:9px;
  background:#dddddd;
  padding:2px;
  margin:2px;
  border-radius:2px;
}

.singleVibeId {
  color:red;
  font-size:12px;
}