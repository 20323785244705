.experiencePlacesContainer {
  display:flex;
  flex-direction: column;
}

.pleaseAddExperience {
  background:#dedede;
  text-align: center;
  padding:20px;
}

.experiencePlaceItem {
  background:#dedede;
  text-align: center;
  padding:20px;
    margin-top:5px;
    margin-bottom:5px;
    border-radius: 5px;
}
