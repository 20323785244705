/* The following block can be included in a custom.scss */

/* make the customizations */
$doug-red: #eb2227;
$sectionbackground: #f8f6f4;
$notquiteblack: #303030;
$theme-colors: (
    "info": $doug-red,
    "danger": teal
);
@import url(http://fonts.googleapis.com/css?family=Roboto+Slab|Open+Sans:400italic,700italic,400,700);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@200;400;600;700&display=swap');

// Font Variables
$roboto-slab: 'Roboto Slab', serif;
$open-sans: 'Open Sans', sans-serif;

// Styles
body {
  font-family: 'Roboto Slab', serif;
  font-weight: normal;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Rubik Mono One', sans-serif;
  font-weight: normal;
  letter-spacing: -1px;
}

.button {
  font-family: 'Rubik Mono One', serif;
  font-weight: bold;
}
.padrow {
  padding-top:50px;
  padding-bottom:50px;
  margin-bottom:50px;

}
.marginrowbottom {
  margin-bottom:50px;
}
.rowmargintop {
  padding-top:75px;
}
.color {
  background:$sectionbackground;
}
.whitetext {
  color:$notquiteblack;
}
.smallpara {
  max-width:600px;
  margin:auto;
  text-align:center;
  margin-bottom:5px;
}
.lottieWrap {
  background:#fafafa;
  border-radius:10px;
  padding:15px;
}
.lottieName {
  font-family: 'Rubik Mono One', serif;
  text-align:center;
}
.lottieDesc {
  text-align:center;
  font-size:9px;
}
.dougFooter {
  background:$sectionbackground;
  padding-top:75px;
  padding-bottom:75px;
}
.contentImageWrap {
  width:90%;
  max-width:400px;
  margin-top:25px;
  margin-bottom:25px;
}

.contentImageWrap > img {
  width:100%;
}
.imageCaption {
  font-style:italic;
  padding-left:15px;
}
.contentSectionWrap {
  margin-top:25px;
}

.getBox {
  margin-top:25px;
}

/* old doug css */

.aboutDougContainer {
  padding-top:15px;
  padding-bottom:125px;
  width:90%;
  margin:auto;
}
.aboutDougImageContainer {
  width:100%;
  max-width:1000px;
  margin:auto;
}
.aboutDougImageContainer > img {
  width:100%;
}
.aboutDougTextContainer {
  width:100%;
  max-width:800px;
  font-family: 'Source Serif Pro';
  font-size:18px;
  margin:auto;
}
.aboutDougPaddingWrap {
  margin-bottom:75px;
}
.topTitle {
  font-family: 'Source Serif Pro';
  font-size:140px;
  font-weight:bold;
  letter-spacing:-5px;
  color:#404040;
  line-height:.8;
}
.contentBody {
  margin-top:50px;
  text-align:left;
}
.sub1 {
  font-family: 'Source Serif Pro';
  font-size:40px;
  font-weight:bold;
  letter-spacing:-1px;
  color:#404040;
  line-height:.8;
}
.contentSectionWrap {
  margin-bottom:50px;
  font-size:25px;
  font-family: 'Source Serif Pro';
  letter-spacing:-1px;
}
.bodyList > li{
}
.extraBottomMargin {
  margin-bottom:100px;
}
.contentImageWrap {
  width:95%;
  max-width:600px;
  margin:auto;
  margin-bottom:100px;
}
.contentImageWrap > img {
  width:100%;
}
.imageCaption {
  font-style:italic;
  margin-top:5px;
  text-align:center;
}

.half {
  width:50%;
}
.formInput {
  width:100%;
}

.dougTC {
  padding:15px;
  border:1px solid lightgrey;
}
.mobileLottie {
  padding-top:50px;
  padding-bottom:50px;
}

.userCardTop {
  margin-bottom:25px;
}
.userBusinesses {
  margin-top:25px;
}
@media(max-width:767px) {
  .marginrowbottom {
    margin-bottom:0px;
  }
  .mobileLottie {
    padding-top:35px;
    padding-bottom:35px;
  }
  .bigHeader {
    font-size:40px;
    letter-spacing:-3px;
  }
  .getDougContainer {
    height:40vh;
    padding-top:15px;
  }
  .allLove {
    width:200px;
  }
  .aboutDougHomeWrap {
    padding-top:15px;
    padding-bottom:25px;
  }
  .navHeader {
    flex-direction:column;
    align-items:center;
    padding-top:0px;
  }
  .navLogo {
    width:95px;
  }
  .mobileHide {
    display:none;
  }
  .navRight {
    display:none;
  }
  .mobileDiscover {
    text-align:center;
  }
  .navLogoWrap {
    margin-bottom:0px;
    margin-top:5px;
  }
  .navLeft {
    margin-bottom:10px;
  }
  .mainContainer {
    padding-top:15px;
  }
  .getDougContainer {
    padding-top:15px;
  }
  .footerWrap {
    flex-direction:column;
  }
  .footerColumn {
    align-items: flex-start;
  }
  .topTitle {
    font-size:60px;
    letter-spacing:-2px;
  }
}

.vibeImageWrap {
  width:100%;
  padding-top:100%;
  border-radius: 10px;
}

.singleVibeWrap {
  background:#f2f2f2;
  padding:15px;
  margin:10px;
  border-radius:5px;
  width:50%;
  max-width:600px;
}
.singleVibeUserImage {
  border-radius:50%;
  width:50px;
  height:50px;
}
.singleVibeUserBar {
  margin-bottom:10px;
  display:flex;
  flex-direction:row;
  align-items:center;
}
.singleVibeUserBarRight {
  margin-left:10px;
}
.singleVibeUserName {
  display:flex;
  flex-direction:row;
  align-items:center;
}

.singleVibeLocation {
  font-size:9px;
}
.singleVibeTime {
font-size:9px;
}

.singleVibeCommentWrap {
  margin-top:10px;
  font-size:22px;
}
.vibeViewWrapper {
  margin-bottom:50px;
}
.loginSocialWrap {
  margin-top:10px;
}

.profilePicUploadButton {
  position: absolute;
  bottom: 5px;
  left: 5px;
  opacity: .9;
}
.profilePicUploadingTrue {
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}

.businessProfileFormGroupMargin {
  margin-bottom:20px;
}
.businessProfileMap {
  margin-top:20px;
  position:relative;
}
.businessProfileSearchBar {
  margin-top:10px;
  position:absolute;
  top:10px;
  left:10px;
  right:10px;
}

.businessProfileSearchResultItem {
  background:white;
  border:1px solid lightgray;
  padding:8px;
  border-radius: 4px;
  margin-top:5px;
  cursor:pointer;
}


.profilePicHolder {
  background-size:cover !important;
  background-position:center !important;
  position:relative;
}
.profilePicUploadButton {
  position: absolute;
  bottom: 5px;
  left: 5px;
  opacity: .9;
}
.profilePicUploadingTrue {
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}

.businessProfileFormGroupMargin {
  margin-bottom:20px;
}
.businessProfileMap {
  margin-top:20px;
  position:relative;
}
.businessProfileSearchBar {
  margin-top:10px;
  position:absolute;
  top:10px;
  left:10px;
  right:10px;
}

.businessProfileSearchResultItem {
  background:white;
  border:1px solid lightgray;
  padding:8px;
  border-radius: 4px;
  margin-top:5px;
  cursor:pointer;
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
